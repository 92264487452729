









































import { ItemDaVenda, Venda } from '@/models'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class AlterarQuantidadeLimiteDoProduto extends Vue {
	mostra = false
	loading = false
	pagina = 1
	itemVenda: ItemDaVenda | null = null

	headers = [
		{
			text: 'Produto',
			value: 'produto.nome',
			class: 'text-no-wrap',
			width: '100%',
		},
		{
			text: 'Código',
			value: 'displayEans',
			class: 'text-no-wrap',
			align: 'right',
		},
		{ text: 'Quantidade', value: 'quantidade', class: 'text-no-wrap' },
	]

	get venda() {
		return VendaModule.vendaAtual as Venda
	}

	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	mostrar(item: ItemDaVenda) {
		this.itemVenda = item
		this.mostra = true
	}

	get itensFormatados() {
		if (!this.itemVenda) return []
		const item = [
			{
				displayEans: this.itemVenda.produto.eans.length
					? this.itemVenda.produto.eans.join(', ')
					: this.itemVenda.produto.sku,
				produto: this.itemVenda.produto,
				id: this.itemVenda.id,
				preco: this.itemVenda.preco,
				precoDeCusto: this.itemVenda.precoDeCusto,
				quantidade: this.itemVenda.quantidade,
				desconto: this.itemVenda.desconto,
				idConjunto: this.itemVenda.idConjunto,
				descontoVarejo: this.itemVenda.descontoVarejo,
				percentualCashback: this.itemVenda.percentualCashback,
				diasParaEfetivarCashback: this.itemVenda.diasParaEfetivarCashback,
				isBrinde: false,
				idItemNotaOrigem: null,
				chaveNotaOrigem: null,
				diasParaExpirarCashback: this.itemVenda.diasParaExpirarCashback,
				possuiDescontoPorTabela: this.itemVenda.possuiDescontoPorTabela,
				valorDescontoRateadoDoItem: this.itemVenda.valorDescontoRateadoDoItem,
				itemDeDevolucao: this.itemVenda.itemDeDevolucao,
				isTotalmenteTrocado: this.itemVenda.isTotalmenteTrocado,
				motivoCancelamento: this.itemVenda.motivoCancelamento,
				isCompra: false,
				idItemOrigem: this.itemVenda.idItemOrigem,
				isDaVendaReaberta: false,
				vendedor: this.itemVenda.vendedor,
				autenticadoParaLiberarProdutoSemEstoque: this.itemVenda.autenticadoParaLiberarProdutoSemEstoque,
			},
		]
		return item
	}

	cancelar() {
		this.mostra = false
	}

	concluir() {
		this.loading = true
		if(this.itemVenda && this.venda.itens.length <= 0) {
			this.venda.itens.push(this.itensFormatados[0])
		} else if (this.itemVenda && this.venda.itens.length >= 0) {
			this.venda.itens.forEach(itemVenda => {
				if (itemVenda.produto.id === this.itensFormatados[0]?.produto.id) {
					itemVenda.quantidade += this.itensFormatados[0]?.quantidade
				} else if (this.itemVenda) {
					const temIgual = this.venda.itens.filter(
						item => item.produto.id === this.itemVenda?.produto.id,
					)
					if (temIgual.length <= 0) {
						this.venda.itens.push(this.itensFormatados[0])
					}
				}
			})
		}

		this.mostra = false
		this.loading = false
	}
}
